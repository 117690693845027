import method from '@/views/method/method'; import { watch } from 'vue';
<template>
  <!-- 底部 -->
  <div class="footerbox" v-if="showfooter">
    <div class="flex-around">
      <div
        @click="activepage(index, items.urlname)"
        :class="index == activeIndex ? 'active' : ''"
        v-for="(items, index) in list"
        :key="items.name"
      >
        <template v-if="index == activeIndex">
          <div class="footerboximg"><img :src="items.actimgurl" alt="" /></div>
        </template>
        <template v-else>
          <div class="footerboximg"><img :src="items.imgurl" alt="" /></div>
        </template>
        <div>{{ items.name }}</div>
      </div>
    </div>
    <!-- -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 是否显示底部栏
      showfooter: false,
      list: [
        {
          name: "行情",
          urlname: "home",
          imgurl: require("@/assets/images/icon_quotation@2x.png"),
          actimgurl: require("@/assets/images/icon_quotation_sel.png")
        },
        {
          name: "定价",
          urlname: "pricing",
          imgurl: require("@/assets/images/icon_price@2x.png"),
          actimgurl: require("@/assets/images/icon_price_sel.png")
        },
        {
          name: "新闻",
          urlname: "new",
          imgurl: require("@/assets/images/icon_news@2x.png"),
          actimgurl: require("@/assets/images/icon_news_sel.png")
        },
        {
          name: "我的",
          urlname: "person",
          imgurl: require("@/assets/images/icon_personal@2x.png"),
          actimgurl: require("@/assets/images/icon_personal_sel.png")
        }        
      ],
      activeIndex: -1,
      userinfo:{},
      qiyuweidu:''
    };
  },
  methods: {
    activepage(index, urlname) {
      const token_ = localStorage.getItem("token");
      if (urlname == "person" || urlname == "pricing") {
        /** 
          如果没获取到token则去登录页面
          反之则去‘定价’、‘个人’页面
        */
        if (!token_) {
          this.$router.push({ name: "login" });
        } else {
          this.$router.push({ name: urlname });
        }
      } else {
        //行情页面不需要登录状态：可以直接跳转
        this.$router.push({ name: urlname });
      }
    }
  },
  watch: {
    $route: function(newV, oldV) {
      if (
        newV.name == "person" ||
        newV.name == "pricing" ||
        newV.name == "home" ||
        newV.name == "new"
      ) {
        this.showfooter = true;
      } else {
        this.showfooter = false;
      }
      this.activeIndex = -1;
      if(newV.name == "person"){
        this.activeIndex = 3;
      } else if (newV.name == "new") {
        this.activeIndex = 2;
      } else if (newV.name == "pricing") {
        this.activeIndex = 1;
      } else if (newV.name == "home") {
        this.activeIndex = 0;
      }
      // else if (newv.name == "kefu") {
      //   this.activeIndex = 3
      // }
    }
  },

};
</script>
<style lang="scss" scoped="true">
.active {
  color: #29b6f6 !important;
}
.footerbox {
  position: relative;
  background: #2b3036;
  width: 100%;
  height: 100px;
  color: #d4dee3;
  font-size: 22px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 88999;
  .footerboximg {
    margin: 15px auto 5px;
    width: 40px;
    height: 40px;
    
  }
}
</style>
